.online_group_therapy {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    color: #3D5581;
    margin-top: 16px;
}


.view_online_group_therapy {
    width: 100%;
    display: flex;
    justify-content: center;
}





