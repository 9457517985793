.btn {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Brooklyn, sans-serif;
    font-weight: 700;
    color: white;
    cursor: pointer;
    background-color: #E19D89;
    height: 48px;
    width: 33%;
    border-radius: 10px;
}

@media (max-width: 600px) {
    .btn {
        width: 60%;
    }
}

.btnStepOne {
    cursor: pointer;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Brooklyn, sans-serif;
    font-weight: 700;
    color: white;
    background-color: #E19D89;
    height: 48px;
    width: 60%;
    padding-right: 8px;
    padding-left: 8px;
    border-radius: 10px;
}

@media (max-width: 600px) {
    .btnStepOne {
        width: 90%;
    }
}

.disabledBtn {
    margin-top: 20px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #e3e3e3;
    height: 48px;
    width: 90%;
    border-radius: 10px;
}

.viewText, .viewTextSelected {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    margin-top: 0;
    border-radius: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    border: 2px solid rgba(61, 85, 129, 0.5);
}

.viewText .leftSide, .viewText .rightSide {
    padding: 20px;
}

.viewText .leftSide {
    width: calc(28% - 12px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.viewText .leftSide img {
    margin-bottom: 10px;
    border-style: solid;
    border-width: 2px;
    border-color: #3d5581;
    border-radius: 100vw;
    object-fit: cover;
    width: 150px;
    height: 150px;
}

.viewText .leftSide .userNameLicense {
    font-family: Europa, sans-serif;
    color: #1d1d1f;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.viewText .rightSide {
    width: calc(72% - 12px);
    padding-top: 50px;
    min-height: 190px;
    display: flex;
    flex-direction: column;
}

.viewText .rightSide .groupType {
    width: fit-content;
    height: auto;
    text-align: center;
    margin-bottom: 15px;
    padding: 2px 20px;
    border-radius: 10px;
    background-color: #e6f2f7;
    font-size: 16px;
    line-height: 1.6;
    font-family: Europa, sans-serif;
    color: #1d1d1f;
    font-weight: 400;
}

.viewText .rightSide .groupTime {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
    line-height: 1.6;
    font-family: Europa, sans-serif;
    color: #1d1d1f;
    font-weight: 400;
    position: relative;
    margin-top: 34px;
}

.viewText .rightSide .groupTime .weekday, .viewText .rightSide .groupTime .time {
    position: relative;
    padding-left: 22px;
}

.viewText .rightSide .groupTime .weekday:after {
    content: ' ';
    display: block;
    position: absolute;
    background: url(../../assets/calendar_icon.png);
    right: unset;
    top: 3px;
    background-size: cover;
    width: 16px;
    height: 16px;
    left: 0;
}

.viewText .rightSide .groupTime .time:after {
    content: ' ';
    display: block;
    position: absolute;
    background: url(../../assets/alarm.png);
    width: 16px;
    height: 16px;
    background-size: cover;
    right: unset;
    top: 3px;
    left: 0;
}

.viewText .rightSide .selectedCircle {
    position: absolute;
    left: auto;
    top: 20px;
    right: 20px;
    bottom: auto;
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 6px;
    border-color: #88b3b5;
    border-radius: 100vw;
    background-color: #88b3b5;
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 30%);
    color: #fff;
    opacity: 0;
    box-sizing: border-box;
}

.viewText.viewTextSelected, .viewText.viewTextSelected:hover {
    border-color: #88b3b5;
    background-color: rgba(136, 179, 181, 0.3);
}

.viewText:hover {
    border-color: #88b3b5;
    background-color: rgba(136, 179, 181, 0.3);
}

.viewText.viewTextSelected .leftSide img {
    border-color: #88b3b5;
}

.viewText.viewTextSelected .rightSide .groupType {
    background-color: #88b3b5;
    color: #fff;
}

.viewText.viewTextSelected .rightSide .selectedCircle {
    opacity: 1;
}

.viewText .rightSide .userDescribe {
    font-size: 16px;
    line-height: 1.6;
    font-family: Europa, sans-serif;
    color: #1d1d1f;
    font-weight: 400;
}

.viewText.error {
    border-color: red;
}

.mainViewStepTwo {
    width: 90%;
    margin-top: 20px;
}

.mainViewStepTwo .show_other {
    width: 100%;
    font-size: 16px;
    line-height: 1.6;
    font-family: Europa, sans-serif;
    color: #1d1d1f;
    font-weight: 400;
    border-radius: 10px;
    background-color: rgba(231, 204, 148, 0.5);
    padding: 4px 32px;
    cursor: pointer;
    box-sizing: border-box;
    text-align: center;
}

.mainViewStepTwo .show_other a {
    text-decoration: underline;
    text-transform: capitalize;
}

.pb-0 {
    padding-bottom: 0;
}

.errorText {
    color: red;
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
}

@media screen and (max-width: 991px) {
    .viewText {
        flex-wrap: wrap;
    }

    .viewText .leftSide, .viewText .rightSide {
        width: 100%;
    }

    .viewText .rightSide {
        padding-top: 20px;
    }

    .viewText .rightSide .groupType {
        margin-left: auto;
        margin-right: auto;
    }

    .viewText .rightSide .groupTime {
        margin-top: 12px;
    }

    .viewText:hover {
        border-color: rgba(61, 85, 129, 0.5);
        background-color: transparent;
    }
}

@media screen and (max-width: 767px) {
    .viewText .rightSide .userDescribe {
        font-size: 14px;
    }
}
