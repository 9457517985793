.mainModal {
    margin: 48px;
    padding: 16px;
    min-height: 200px;
    background-color: white;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 60%;
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 30%);
    border-radius: 10px;


}
@media (max-width: 760px) {
    .mainModal{
        width: 90%;
    }
}
@media (max-width: 450px) {
    .mainModal{
        margin-left: 0;
        margin-right: 0;
    }
}

.mainTextModal {
    /*font-family: 'Montserrat', sans-serif;*/
    font-family: Brooklyn, sans-serif;
    font-size: 28px;
    font-weight: 700;
    color: #3D5581;
    margin-top: 16px;
    text-align: center;
}

.mainSecondTextModal {
    /*font-family: 'Montserrat', sans-serif;*/
    font-size: 20px;
    color: #3D5581;
    font-weight: 700;
    font-family: Brooklyn, sans-serif;
    margin-top: 16px;
    padding-right: 32px;
    padding-left: 32px;
    text-align: center;
}

@media (max-width: 520px) {
    .mainSecondTextModal{
        font-size: 18px;
        padding-right: 0;
        padding-left: 0;
    }
}

.buttonWidget {
    flex: 1;
    list-style-type: none;
    /*padding: 10px 0;*/
    /*text-align: center;*/
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0 10px;
    position: relative;
    border-color: #3d5581bf;
    border-width: 1px;
    background-color: #e6f2f7;
}

.buttonWidget p {
    text-align: center;
}

@media (max-width: 575px) {
    .buttonWidget p {
        font-size: 14px;
    }
}

@media (max-width: 379px) {
    .buttonWidget p {
        font-size: 12px;
    }
}

.textAlignCenter {
    text-align: center;
}

.active {
    background-color: #3d5581bf;
    color: #ffffff;
    font-weight: 500;
}

.buttonWidget:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 21px solid rgba(0, 0, 0, 0);
    border-bottom: 21px solid rgba(0, 0, 0, 0);
    border-left: 10px solid #e6f2f7;
    position: absolute;
    top: 50%;
    margin-top: -21px;
    left: 100%;
    z-index: 3;
}

.active:after {
    border-left: 10px solid #3d5581bf;
}

.buttonWidgetLast {
    flex: 1;
    list-style-type: none;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    border-color: #3d5581bf;
    border-width: 1px;
    background-color: #e6f2f7;
}

.buttonWidgetLast.active {
    background-color: #3d5581bf;
}

.buttonWidgetLast p {
    text-align: center;
}

@media (max-width: 420px) {
    .buttonWidgetLast p{
        font-size: 12px;
    }
}

.viewWidget {
    width: 90%;
    height: 40px;
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    background: #e6f2f7;
    border-radius: 10px;
    overflow: hidden;
}

.imageDone {
    height: 90%;
    aspect-ratio: 1;
}

.viewDone {
    background-color: #3D5581;
    width: 100%;
    height: 250px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center
}

.textDone {
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
}
