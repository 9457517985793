.mainViewStepOne {
    width: 90%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.form-edit {
    margin: 10px 0 0;
    border: none;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-consultation__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-column-gap: 15px;
    column-gap: 15px;
}

.btn-form {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 14px;
}

.btn-form, .btn-item {
    min-height: 48px;
    width: 100%;
    display: flex;
    padding-left: 32px;
    margin-left: -14px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    background-color: #1891e5;
}

.btn-form, .btn-default, .btn-item {
    background: #E19D89;
    cursor: pointer;
    border: none;
    outline: none;
}

.btn-form, .btn-default, .btn-item {
    font-size: 16px;
    line-height: 25px;
    color: #fff;
}

.viewButton {
    margin-top: 10px;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 16px;
}
.viewButtonPay {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 16px;
}

.buttonPay {
    display: flex;
    justify-content: center
}

.textAboutCondition {
    font-size: 12px;
    color: #9E9E9E;
}

.regionDropdown {
    padding-left: 8px;
    height: 48px;
    width: 60%;
    border-radius: 10px;
    background-color: white;
    -webkit-appearance: none;
    border: 1px solid white;
    outline: none;
    color: black;
    margin-left: -16px;
    margin-top: -80px;
}

@media (max-width: 600px) {
    .regionDropdown {
        width: 90%
    }
}

.spinnerView {
    align-items: center;
    display: flex
}

.containerPhoneInput {
    display: flex;
    justify-content: center;
    margin-top: -81px;
}

.inputPhoneInput {
    width: 61% !important;;
    border-radius: 10px !important;;
    padding-left: 10px !important;;
    border: 1px solid white !important;;
    height: 50px !important;;
}

@media (max-width: 600px) {
    .inputPhoneInput {
        width: 91% !important;;
    }
}

.archive-therapist-container__title {
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    color: #171725;
}

.archive-therapist-container__title {
    margin-bottom: 24px;
    padding: 38px 30px 0;
    text-align: center;
}

.error-color {
    color: #fc5a5a;
}

#iamover18 {
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0,0,0,.25);
    border-radius: 0.25em;
    float: left;
    margin-left: -1.5em;
}
