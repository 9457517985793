.coupon-field {
    margin-top: 8px;
}

.coupon-field .input-field__input {
    justify-content: space-between;
}

.coupon-field .couponInputWrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-width: 140px;
    margin-right: 1rem;
}

.coupon-field .inputCard {
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
    outline: none;
}

.coupon-field .inputCard.couponError {
    border-color: red;
}

.coupon-field .inputCard::placeholder {
    text-transform: initial;
}

.coupon-field .apply_btn {
    align-self: flex-start;
    height: 28px;
    line-height: 28px;
    background-color: #3d5581bf;
    cursor: pointer;
    padding: 10px 30px;
    border-radius: 10px;
    font-family: Brooklyn, sans-serif;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    min-width: unset;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.coupon-field .couponErrorMessage {
    flex-basis: 100%;
    padding-top: 0.5rem;
    font-size: 14px;
    color: red;
}

.coupon-field .appliedIcon {
    position: absolute;
    right: 12px;
    top: 12px;
}

@media (max-width: 575px) {
    .coupon-field .apply_btn {
        font-size: 14px;
        padding: 10px 15px;
    }
}
