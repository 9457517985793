.card-element {
    position: relative;
    padding: 15px 10px;
    border: 1px solid red;
    background: white;
    will-change: opacity, transform;
    border-radius: 10px;
    color: black;
}

.mainViewStepThree {
    width: 90%;
    margin-top: 24px;
    display: flex;
    flex-direction: column
}

/*.mainViewStepThree .priceText {*/
/*    display: flex;*/
/*    justify-content: flex-end;*/
/*    margin-left: auto;*/
/*    font-size: 16px;*/
/*    font-weight: 400;*/
/*    line-height: 1.6;*/
/*    color: #3d5581;*/
/*}*/

.mT16 {
    margin-top: 16px;
}

.viewSecondTable {
    flex: 1;
    padding-left: 32px
}

.viewFirstTable{
    display:flex;
    flex-direction:row
}

.viewInputZip{
    display: flex;
    flex-direction: column
}

.form-group {
    margin-bottom: 15px;
}

.label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}

.inputZip {
    margin-top: 16px;
    padding: 15px 10px;
    border: 1px solid #f5f5f5;
    background: white;
    box-shadow: inset 0px 1px 2px #e5e5e5;
    will-change: opacity, transform;
    border-radius: 10px;
    color: black;
}
.inputCard{
    padding: 15px 10px;
    box-shadow: inset 0px 1px 2px #e5e5e5;
    border: 1px solid #f5f5f5;
    background: white;
    will-change: opacity, transform;
    border-radius: 10px;
    color: black;
}

.summary_block {
    font-size: 14px;
    color: #44444E;
    margin-top: 6px;
    margin-bottom: 1rem;
    padding: 1.25rem;
    border-radius: 10px;
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 20%);
}

.summary_block h2 {
    margin-top: 0;
    color: #000000;
}

.summary_block .hr-custom {
    margin: 0.5em 0;
    border-top: 2px solid #f5f5f5;
    height: 1px;
}

.summary_block .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.74rem;
    padding: 6px 0;
}

.summary_block .row div:first-child {
    width: 80%;
}

.summary_block .row div:last-child {
    width: 20%;
    text-align: right;
}

.summary_block .discount {
    font-weight: 500;
}

.summary_block .savings {
    color: #8EB1B3;
    font-size: 13px;
    font-weight: 500;
}

.summary_block .total {
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 16px;
}

.blue-color {
    color: #3d5581;
}

@media screen and (min-width: 992px) {
    .summary_block {
        font-size: 16px;
    }

    .summary_block .savings {
        font-size: 14px;
    }

    .summary_block .total {
        font-size: 18px;
    }
}
