.mainContainer {
    background: url('/src/assets/background.svg') no-repeat bottom/contain;
    background-size: cover;
    min-height: calc(100vh);
    /*justify-content: center;*/
    display: flex;
    flex-direction: column;
}

.simple-logo {
    /*height: 150px;*/
    width: 240px;
    height: 72px;
    /*width: 100%;*/
    margin-top: 50px;
    background: url('/src/assets/logo.png') center/contain no-repeat;
}

.headerContainer {
    width: 100%;
    justify-content: center;
    display: flex;
}

.viewForModal {
    width: 100%;
    display: flex;
    justify-content: center;
}
