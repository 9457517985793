.form-edit .input-field {
    margin-bottom: 30px;
    /*margin-left: -16px;*/
}

.input-field {
    position: relative;
    display: flex;
    flex-direction: column;
}

.input-field__label {
    position: absolute;
    z-index: 1;
    top: -8px;
    left: 20%;
    padding: 0 5px;
    background: #fff;
    white-space: nowrap;
    max-width: 90%;
    color: #3D5581;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
}

@media (max-width: 600px) {
    .input-field__label {
        left: 10%;
    }
}

.select {
    margin-top: -81px;
    width: 60%;
    height: 50px;
    border-color: white;
    outline: none;
    background: white;
    border-width: 0;
    -webkit-appearance: none;
}

@media (max-width: 600px) {
    .select {
        width: 90%;
    }
}

.input-field__labelError {
    position: absolute;
    z-index: 1;
    top: -8px;
    left: 20%;
    padding: 0 5px;
    background: #fff;
    white-space: nowrap;
    max-width: 90%;
    color: #fc5a5a;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
}

@media (max-width: 600px) {
    .input-field__labelError {
        left: 10%;
    }
}


.input-field__error-container {
    min-height: 20px;
    padding-left: 20px;
    line-height: 1;
}

.input-field__input {
    display: flex;
    justify-content: center;
}

.input {
    padding-left: 16px;
    height: 48px;
    width: 60%;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #3D5581;
    outline: none;
}

@media (max-width: 600px) {
    .input {
        width: 90%;
    }
}

.inputError {
    padding-left: 16px;
    height: 48px;
    width: 60%;
    border-radius: 10px;
    border: 1px solid #fc5a5a;
    outline: none;
}

@media (max-width: 600px) {
    .inputError {
        width: 90%;
    }
}

.input-field__error-container {
    min-height: 20px;
    padding-left: 20px;
    line-height: 1;
}
